import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Carousel, {
  Dots,
  arrowsPlugin,
  autoplayPlugin,
} from "@brainhubeu/react-carousel"
import TruckPromo from "../images/truck-promo.png"
import SolarAPU from "../images/solar-apu.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import Blue from "@material-ui/core/colors/blue"

const IndexPage = () => {
  const [value, setValue] = useState(0)

  const onChange = value => {
    setValue(value)
  }

  return (
    <div>
      <Layout>
        <div style={{ paddingTop: "2rem", margin: "0 auto" }}>
          <Carousel
            plugins={[
              "infinite",
              {
                resolve: autoplayPlugin,
                options: {
                  interval: 8000,
                },
              },
              {
                resolve: arrowsPlugin,
                options: {
                  arrowLeft: (
                    <button>
                      <FontAwesomeIcon name={faArrowLeft} />
                    </button>
                  ),
                  arrowLeftDisabled: (
                    <button>
                      <FontAwesomeIcon name={faArrowLeft} />
                    </button>
                  ),
                  arrowRight: (
                    <button>
                      <FontAwesomeIcon name={faArrowRight} />
                    </button>
                  ),
                  arrowRightDisabled: (
                    <button>
                      <FontAwesomeIcon name={faArrowRight} />
                    </button>
                  ),
                  addArrowClickHandler: true,
                },
              },
            ]}
            value={value}
            onChange={onChange}
            animationSpeed={500}
            offset={20}
          >
            <Link to="solar-apu">
              <img src={SolarAPU} />
            </Link>
            <img src={TruckPromo} />
          </Carousel>
          <Dots
            value={value}
            onChange={onChange}
            thumbnails={[
              <img
                key={1}
                style={{ height: "2rem", width: "3rem" }}
                src={SolarAPU}
              />,
              <img
                key={2}
                style={{ height: "2rem", width: "3rem" }}
                src={TruckPromo}
              />,
            ]}
          />
        </div>
        <SEO title="Welcome to LA ITM" />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <h1 style={{ color: "rgb(23, 38, 70)" }}>Welcome to LA ITM</h1>
          <p style={{ fontSize: "16px" }}>
            ITM Equipment company was formed on August 2012. The business of ITM
            Equipment is the sale of environmentally friendly, air conditioning
            systems and other parts and equipment to the commercial trucking
            industry.
          </p>
          <h3 style={{ color: "rgb(23, 38, 70)" }}>Our Goal</h3>
          <p style={{ fontSize: "16px" }}>
            <p style={{ fontSize: "16px" }}>
              We are focused on the development of products that will increase
              the efficiency of the equipment used for refrigerating truck
              cargo, and heating and cooling the truck cabs, while offering the
              advantages of reduced carbon emissions through battery powered
              systems.
            </p>
            <p>
              ITM Equipment's extensive expertise in the trucking industry has
              enabled the company to decipher what products would save trucking
              companies money and time, all while helping the planet to be a
              better place to live. With existing product lines and services,
              the advancement of technology, and continuous evaluation of new
              product lines, we are able to pursue the goal of becoming every
              trucking company's one-stop shop.
            </p>
          </p>
          <h3 style={{ color: "rgb(23, 38, 70)" }}>What We Provide</h3>
          <p style={{ fontSize: "16px" }}>
            ITM is committed to providing efficient and reliable services across
            all its business segments to its valued customers where safety is
            our top priority. ITM will commit its resources to safe and
            environmentally sound operating practices. That will result in
            providing our customers with assurance of safe, dependable service.
            We aim to be recognized among current and prospective clients as a
            premier provider of transportation services, to be the leader &
            innovator in the transportation industry.
          </p>
          <div>
            <p></p>
          </div>
        </div>
        <div
          style={{
            background: "#0D1F2D",
            borderRadius: "0.5rem",
            padding: "2rem",
            color: "white",
          }}
        >
          <h2 style={{ textAlign: "center" }} id="contact-us">
            Contact Us
          </h2>
          <div
            id="contact-us"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "row",
              maxWidth: "20rem",
              margin: "0 auto",
            }}
          >
            <div
              style={{ minWidth: "10rem", maxWidth: "15rem" }}
              id="contact-us-sub"
            >
              Reach out to our team, and we will get back to you as soon as
              possible.
            </div>
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: "1rem" }}>Email: </div>
                <a
                  href="mailto: mariela@itmeco.com"
                  style={{ color: "#72bcd4" }}
                >
                  mariela@itmeco.com
                </a>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: "1rem", width: "max-content" }}>
                  Phone Number:{" "}
                </div>
                <a
                  style={{}}
                  href="tel:562-699-9777"
                  style={{ color: "#72bcd4" }}
                >
                  562.699.9777
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default IndexPage
